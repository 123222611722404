<template>
  <div
    class="container"
    id="deviceManage"
    v-title
    :data-title="$t('i18n.deviceManage')"
  >
    <div id="outer-title">{{ $t("i18n.deviceManage") }}</div>
    <div class="loading" v-loading="loading">
      <el-button-group v-has="'编辑终端'">
        <el-button @click="controls('standby')">
          {{ $t("i18n.standby") }}
        </el-button>
        <el-button @click="controls('wakeup')">
          {{ $t("i18n.awaken") }}
        </el-button>
        <el-button @click="controls('restart')">
          {{ $t("i18n.restart") }}
        </el-button>
        <el-button @click="volumeFunc">{{ $t("i18n.adjustVolume") }}</el-button>
        <el-button @click="controls('snapshot')">
          {{ $t("i18n.screenshot") }}
        </el-button>
        <el-button @click="controls('clear')">
          {{ $t("i18n.emptyProgram") }}
        </el-button>
      </el-button-group>
      <jl-table
        :tableData="tableData"
        @handleEdit="handleEdit"
        @getEditData="getEditData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
        @handleSelectionChange="handleSelectionChange"
      >
      </jl-table>
    </div>
    <el-dialog
      :title="$t('i18n.deviceInfo')"
      v-model="dialogDevice"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <el-divider>{{ $t("i18n.baseInfo") }}</el-divider>
      <el-descriptions column="2">
        <el-descriptions-item :label="$t('i18n.deviceName')">
          {{ detailData.name }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('i18n.organization')">
          {{ detailData.groups }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('i18n.deviceDirection')">
          <span v-if="detailData.direction === -1">{{ $t("i18n.none") }}</span>
          <span v-else-if="detailData.direction === 1">
            {{ $t("i18n.horizontalScreen") }}
          </span>
          <span v-else>{{ $t("i18n.verticalScreen") }}</span>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('i18n.bindingTime')">
          {{ detailData.bindingTime }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('i18n.registrationTime')">
          {{ detailData.registerTime }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('i18n.status')">
          <span v-if="detailData.status === 1" style="color: #67c23a">
            {{ $t("i18n.online") }}
          </span>
          <span v-else style="color: #f56c6c">{{ $t("i18n.offline") }}</span>
        </el-descriptions-item>
      </el-descriptions>
      <el-divider>{{ $t("i18n.deviceInfo") }}</el-divider>
      <el-descriptions column="2">
        <el-descriptions-item :label="$t('i18n.deviceMAC')">
          {{ detailData.mac }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('i18n.deviceSN')">
          {{ detailData.sn }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('i18n.IPAddress')">
          {{ detailData.ip }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('i18n.screenInformation')">
          {{ detailData.screenInfo }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('i18n.deviceMemory')">
          {{ detailData.memory }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('i18n.deviceStorage')">
          {{ detailData.disk }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('i18n.exitPassword')">
          {{ detailData.exitPassword }}
        </el-descriptions-item>
      </el-descriptions>
      <el-divider>{{ $t("i18n.versionInformation") }}</el-divider>
      <el-descriptions column="2">
        <el-descriptions-item :label="$t('i18n.firmwareVersion')">
          {{ detailData.firmware }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('i18n.applicationVersion')">
          {{ detailData.appVersion }}
        </el-descriptions-item>
      </el-descriptions>
      <el-divider>{{ $t("i18n.screenshot") }}</el-divider>
      <div class="demo-image__preview" v-if="detailData.screenshot">
        <el-image
          style="width: 100px; height: 100px"
          :src="detailData.screenshot"
          :preview-src-list="srcList"
        ></el-image>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.programList')"
      v-model="dialogProgram"
      :close-on-click-modal="false"
      destroy-on-close
      width="80%"
    >
      <jl-table
        :tableData="programData"
        :options="programOptions"
        :columns="programColumns"
        :operates="programOperates"
        :total="programTotal"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </el-dialog>
    <el-dialog
      custom-class="previewDialog"
      :title="$t('i18n.preview')"
      v-model="dialogPreviewVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <preview :content="info"></preview>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.adjustVolume')"
      v-model="dialogVolumeVisible"
      destroy-on-close
    >
      <el-slider v-model="volume"></el-slider>
      <template #footer>
        <el-button type="primary" @click="changeVolume">
          {{ $t("i18n.confirm") }}
        </el-button>
        <el-button @click="dialogVolumeVisible = false">
          {{ $t("i18n.cancel") }}
        </el-button>
      </template>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.rescueWorkers')"
      v-model="dialogHelper"
      :close-on-click-modal="false"
      destroy-on-close
      width="80%"
    >
      <el-transfer
        v-model="helper"
        filterable
        :filter-method="filterMethod"
        :titles="['所有人员', '救援人员']"
        :data="allPerson"
        target-order="push"
        @right-check-change="chooseUpOrDown"
      >
        <template #right-footer>
          <el-button
            class="transfer-footer"
            size="mini"
            style="margin-left: 5px"
            @click="handleUp"
          >
            上移
          </el-button>
          <el-button class="transfer-footer" size="mini" @click="handleDown">
            下移
          </el-button>
        </template>
      </el-transfer>
      <template #footer>
        <el-button type="primary" @click="setHelper">
          {{ $t("i18n.confirm") }}
        </el-button>
        <el-button @click="dialogHelper = false">
          {{ $t("i18n.cancel") }}
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlTable from "../../components/table";
import preview from "./Preview";
export default {
  name: "DeviceList",
  components: {
    jlTable,
    preview,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      helper: [],
      submitHelper: [],
      selectHelper: [],
      allPerson: [],
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: proxy.$defined.btnPermission("添加终端"), // 是否启用新增功能
        isEdit: proxy.$defined.btnPermission("编辑终端"), // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: true, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "name",
          label: "deviceName",
          search: true,
          type: "input",
          width: 140,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "mac",
          label: "deviceMAC",
          type: "input",
          width: 200,
        },
        {
          prop: "sn",
          label: "deviceSN",
          type: "input",
          rows: false,
        },
        {
          prop: "groups",
          label: "organization",
          width: 180,
          type: "select",
          props: { label: "name", value: "name" },
          data: [],
          search: true,
        },
        {
          prop: "volumn",
          label: "volumn",
          align: "center",
          row: false,
          formatter: (row) => {
            return row.volumn === -1 ? t("i18n.none") : row.volumn;
          },
        },
        {
          prop: "face",
          label: "faceRecognition",
          align: "center",
          row: false,
          formatter: (row) => {
            return row.face === -1
              ? t("i18n.none")
              : row.face === 1
              ? t("i18n.turnOn")
              : t("i18n.turnOff");
          },
        },
        {
          prop: "dualScreen",
          label: "doubleScreenSwitch",
          align: "center",
          row: false,
          formatter: (row) => {
            return row.dualScreen === -1
              ? t("i18n.none")
              : row.dualScreen === 1
              ? t("i18n.turnOn")
              : t("i18n.turnOff");
          },
        },
        {
          prop: "direction",
          label: "deviceDirection",
          align: "center",
          type: "switch",
          value: "0",
          text: {
            aText: "horizontalScreen",
            aVal: 1,
            iText: "verticalScreen",
            iVal: 0,
          },
          formatter: (row) => {
            return row.direction === -1
              ? t("i18n.none")
              : row.direction === 1
              ? t("i18n.horizontalScreen")
              : t("i18n.verticalScreen");
          },
        },
        {
          prop: "bindedElevatorId",
          label: "elevatorNo",
          rows: false,
          type: "remote",
          props: { label: "number", value: "id" },
          rules: [{ required: true, message: t("i18n.input") }],
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteEleMethod(val);
          },
        },
        {
          prop: "exitPassword",
          label: "exitPassword",
          type: "password",
          rows: false,
        },
        {
          prop: "workTime",
          label: "workingHours",
          align: "center",
          row: false,
        },
        {
          prop: "status",
          label: "status",
          align: "center",
          row: false,
          formatter: (row) => {
            if (row.status == 1) {
              return `<span style="color:#67c23a">${t("i18n.online")}</span>`;
            } else {
              return `<span style="color:#f56c6c">${t("i18n.offline")}</span>`;
            }
          },
        },
        {
          prop: "",
          label: "rescueWorkers",
          rows: false,
          format: () => {
            return `<button onclick="selectHelper()" type="button"
              class="el-button el-button--primary is-round">
              选择救援人员
            </button>`;
          },
        },
        {
          prop: "helper",
          rows: false,
          format: (val) => {
            if (!val) {
              return;
            }
            let person = val.split(",");
            let str = "<ul>";
            for (let i = 0; i < person.length; i++) {
              str += "<li>" + (i + 1) + "、" + person[i] + "</li>";
            }
            str += "</ul>";
            return str;
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 300,
        fixed: "right",
        list: [
          {
            label: "details",
            icon: "el-icon-view",
            show: true,
            type: "inner",
            method: (index, row) => {
              proxy.$api.advert.deviceDetail({ id: row.id }).then((res) => {
                state.dialogDevice = true;
                state.detailData = res.data;
                state.srcList = [];
                state.srcList.push(res.data.screenshot);
              });
            },
          },
          {
            label: "programDetails",
            icon: "el-icon-view",
            show: true,
            type: "inner",
            method: (index, row) => {
              state.editId = row.id;
              getProgramByDevice(row.id);
            },
          },
          {
            label: "unbundling",
            icon: "el-icon-delete",
            show: proxy.$defined.btnPermission("解绑终端"),
            type: "inner",
            method: (index, row) => {
              proxy.$defined.confirm(
                () => {
                  proxy.$api.advert.deleteDevice(row.id).then(() => {
                    init(state.formInline);
                    proxy.$defined.tip(
                      t("i18n.delete") + t("i18n.success"),
                      "success"
                    );
                  });
                },
                t("i18n.doYouWantToDelete"),
                t("i18n.delete")
              );
            },
          },
        ],
      },
      total: 0,
      loading: true,
      editData: {},
      dialogDevice: false,
      detailData: "",
      srcList: [],
      dialogProgram: false,
      programData: [],
      programTotal: 0,
      programColumns: [
        {
          prop: "name",
          label: "programName",
          width: 180,
        },
        {
          prop: "screen",
          label: "playArea",
          align: "center",
          formatter: (row) => {
            return row.screen == 1
              ? t("i18n.homeScreen")
              : t("i18n.secondaryScreen");
          },
        },
        {
          label: "broadcastDate",
          align: "center",
          width: 210,
          formatter: (row) => {
            return row.effectiveDateBegin + "~" + row.effectiveDateEnd;
          },
        },
        {
          label: "timeSlot",
          align: "center",
          width: 180,
          formatter: (row) => {
            return row.effectiveTimeBegin + "~" + row.effectiveTimeEnd;
          },
        },
        {
          label: "programDuration",
          align: "center",
          formatter: (row) => {
            return row.duration + "s";
          },
        },
        {
          prop: "createdDate",
          label: "createdDate",
          align: "center",
          width: 180,
        },
        {
          prop: "status",
          label: "status",
          align: "center",
          formatter: (row) => {
            if (row.status == 0) {
              return `<span>${t("i18n.toBeReviewed")}</span>`;
            } else if (row.status == 1) {
              return `<span style="color:#67c23a">
                ${t("i18n.available")}
              </span>`;
            } else if (row.status == 2) {
              return `<span style="color:#f56c6c">
                ${t("i18n.unavailable")}
              </span>`;
            }
          },
        },
      ],
      programOperates: {
        width: 160,
        fixed: "right",
        list: [
          {
            label: "preview",
            icon: "el-icon-view",
            show: true,
            type: "inner",
            method: (index, row) => {
              state.dialogPreviewVisible = true;
              state.info = row.content;
            },
          },
          {
            label: "delete",
            icon: "el-icon-delete",
            show: true,
            type: "inner",
            method: (index, row) => {
              proxy.$defined.confirm(
                () => {
                  proxy.$api.advert.deleteProgram(row.id).then(() => {
                    getProgramByDevice(state.editId);
                    proxy.$defined.tip(
                      t("i18n.delete") + t("i18n.success"),
                      "success"
                    );
                  });
                },
                t("i18n.doYouWantToDelete"),
                t("i18n.delete")
              );
            },
          },
        ],
      },
      programOptions: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: false, // 是否翻页
      },
      dialogPreviewVisible: false,
      info: "",
      editId: "",
      dialogVolumeVisible: false,
      volume: "",
      multipleSelection: [],
      dialogHelper: false,
    });

    const init = async (params) => {
      state.loading = true;
      params.descs = "created_date";
      params.current = params.page;
      params.size = params.pageSize;
      const { data } = await proxy.$api.advert.deviceList(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const getAllUser = async () => {
      let { data } = await proxy.$api.system.getAllUser();
      let arr = [];
      data.map((item) => {
        arr.push({
          label: item,
          key: item,
        });
      });
      state.allPerson = arr;
    };
    getAllUser();

    const getCompanyByPerson = async () => {
      // 获取个人相关公司
      let { data } = await proxy.$api.system.getCompanyByPerson("");
      state.columns.map((item) => {
        if (item.prop === "groups") {
          item.data = data;
        }
      });
    };
    getCompanyByPerson();

    const handleSelectionChange = (val) => {
      let ids = [];
      val.map((item) => {
        ids.push(item.id);
      });
      state.multipleSelection = ids;
    };

    const handleEdit = async (val, pages) => {
      // 新增/编辑数据
      let msg = val.id ? "edit" : "new";
      if (val.id) {
        for (let k in val) {
          if (val[k] !== undefined) {
            state.editData[k] = val[k];
          }
        }
        val = JSON.parse(JSON.stringify(state.editData));
      }
      val.id
        ? await proxy.$api.advert.updateDevice(val)
        : await proxy.$api.advert.addDevice(val);
      proxy.$defined.tip(t("i18n." + msg) + t("i18n.success"), "success");
      // 初始化 刷新
      init(pages);
    };

    const getProgramByDevice = async (id) => {
      // 通过设备ID 查询节目
      let { data } = await proxy.$api.advert.getProgramByDevice(id);
      state.programData = data;
      state.dialogProgram = true;
    };

    const controls = async (method) => {
      // 按钮组
      var ids = state.multipleSelection;
      if (ids.length > 0) {
        await proxy.$api.advert.controls(method, ids.join(","));
        proxy.$defined.tip(t("i18n.update") + t("i18n.success"), "success");
        init(state.formInline);
      } else {
        proxy.$defined.tip("请先选择终端！");
      }
    };

    const remoteEleMethod = (val) => {
      var index = state.columns.findIndex((item) => {
        return item.prop === "bindedElevatorId";
      });
      if (val) {
        state.columns[index].loading = true;
        setTimeout(() => {
          proxy.$api.maintenance
            .getAllEle({ elevatorNumber: val })
            .then((data) => {
              state.columns[index].data = data.data;
              state.columns[index].loading = false;
            });
        }, 1000);
      } else {
        state.columns[index].data = [];
      }
    };

    const volumeFunc = () => {
      // 调节音量
      var ids = state.multipleSelection;
      if (ids.length > 0) {
        state.dialogVolumeVisible = true;
      } else {
        proxy.$defined.tip("请先选择终端！");
      }
    };

    const changeVolume = async () => {
      var ids = state.multipleSelection;
      await proxy.$api.advert.changeVolume(ids.join(","), state.volume);
      proxy.$defined.tip(t("i18n.update") + t("i18n.success"), "success");
      init(state.formInline);
      state.dialogVolumeVisible = false;
    };

    window.selectHelper = () => {
      if (state.editData.helper) {
        state.helper = state.editData.helper
          ? state.editData.helper.split(",")
          : [];
      }
      state.dialogHelper = true;
    };

    const getEditData = (row) => {
      state.editData = row;
    };

    const filterMethod = (query, item) => {
      return item.label.indexOf(query) > -1;
    };

    const setHelper = () => {
      state.submitHelper = [];
      state.dialogHelper = false;
      state.columns.map((row) => {
        if (row.prop === "helper") {
          row.isUpdate = true;
          row.value = state.helper.join(",");
        }
      });
    };

    const chooseUpOrDown = (val) => {
      state.selectHelper = val;
    };

    const handleUp = () => {
      state.selectHelper.map((item) => {
        let index = state.helper.findIndex((key) => {
          return item === key;
        });
        if (index !== 0) {
          //在上一项插入该项
          state.helper.splice(index - 1, 0, state.helper[index]);
          //删除后一项
          state.helper.splice(index + 1, 1);
        }
      });
    };

    const handleDown = () => {
      state.selectHelper.map((item) => {
        let index = state.helper.findIndex((key) => {
          return item === key;
        });
        if (index !== state.helper.length - 1) {
          // 在下一项插入该项
          state.helper.splice(index + 2, 0, state.helper[index]);
          // 删除前一项
          state.helper.splice(index, 1);
        }
      });
    };

    return {
      ...toRefs(state),
      init,
      handleSelectionChange,
      handleEdit,
      getProgramByDevice,
      controls,
      volumeFunc,
      changeVolume,
      filterMethod,
      setHelper,
      handleUp,
      handleDown,
      chooseUpOrDown,
      getEditData,
      remoteEleMethod,
    };
  },
};
</script>

<style lang="scss">
#deviceManage {
  .loading {
    position: relative;
    .el-button-group {
      position: absolute;
      top: 44px;
      right: 105px;
    }
  }
  .el-transfer-panel__body.is-with-footer {
    .el-checkbox-group {
      padding-bottom: 40px;
    }
  }
}
</style>
